import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SafeHtmlComponent } from "./safe-html.component";

@NgModule({
  declarations: [
    SafeHtmlComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [SafeHtmlComponent]
})
export class SafeHtmlModule { }
