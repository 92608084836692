import {
  AbstractControl,
  UntypedFormControl,
  FormGroupDirective,
  NgControl,
  NgForm,
  NG_VALIDATORS,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from "@angular/core";
import {
  BehaviorSubject,
  combineLatestWith,
  Observable,
  Subscription,
  take,
} from "rxjs";
import { map, startWith, tap } from "rxjs/operators";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import cloneDeep from "lodash/cloneDeep";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { TranslateService } from "@ngx-translate/core";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatOptionSelectionChange } from "@angular/material/core";
import { from } from "rxjs";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { DropdownOption, DropdownSortByOption } from "./dropdown";

export class customErrorStateMatcher implements ErrorStateMatcher {
  constructor(private readonly control) {
    this.control = control;
  }
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return this.control && this.control.invalid && this.control.touched;
  }
}

type SelectedValue = any;

@Component({
  selector: "lib-fmx-dropdown",
  templateUrl: "./fmx-dropdown.component.html",
  styleUrls: ["./fmx-dropdown.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FmxDropdownComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FmxDropdownComponent),
      multi: true,
    },
  ],
})
export class FmxDropdownComponent
implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
  @ViewChild("inputElement") inputElement: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) auto: MatAutocompleteTrigger;
  @ViewChild(CdkVirtualScrollViewport, { static: false }) cdkVirtualScrollViewPort: CdkVirtualScrollViewport;

  @Input() displayKey = "label";

  @Input() multiple = false;

  /** True if the collection of elements can be enhanced by user defined values, false otherwise */
  @Input() dynamic = false;

  @Input() required = false;

  @Input() resetButton = true;

  @Input() appearance: MatFormFieldAppearance = "fill";

  @Input() placeholder: string;

  @Input() notFoundMessage: string;

  @Input() requiredError: string;

  @Input() sortBy = DropdownSortByOption.ASC;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: any = new EventEmitter<any>();

  public selectControl = new UntypedFormControl();
  public isPanelOpen = false;
  public floatLabel: "always" | "auto";
  public filteredOptions: Observable<DropdownOption[] | string>;
  public filterString = "";
  public isInValid = false;
  public isDisabled = false;
  public fillInput = false;
  public matcher: ErrorStateMatcher;
  public virtualScrollTakesPlaceAt = 200;

  /**
   * The collection of initial options passed by parent components to construct a part of the internal view model.
   * It may e enhanced with additional elements if {@link dynamic} is set to true.
   */
  private _available: DropdownOption[] = [];
  /**
   * The current (selected) value part of the internal view model. The other part of the model is the available
   * values (see {@link _available})
   */
  private _value: SelectedValue;

  private _sort = true;
  /**
   * The collection of options (elements of the dropdown) that:
   * <ul>
   *   <li>can be used by to build a read-only view of the current selection</li>
   *   <li>can be enhanced by additional elements if {@link dynamic} is set to true</li>
   * </ul>
   */
  private options: DropdownOption[] = [];

  private ngControl: NgControl;
  private _panelOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private subscriptions: Subscription = new Subscription();
  private noResults = false;

  private selectedValues = new BehaviorSubject<SelectedValue>([]);
  private availableValues = new BehaviorSubject<DropdownOption[]>([]);
  private sortValues = new BehaviorSubject<boolean>(true);

  constructor(
    @Optional() @Self() private inj: Injector,
    private cdRef: ChangeDetectorRef,
    private translateService: TranslateService
  ) {
    this.subscriptions.add(
      this.selectedValues
        .asObservable()
        .pipe(
          combineLatestWith(
            this.availableValues.asObservable(),
            this.sortValues.asObservable()
          )
        )
        .subscribe(
          ([selected, available, sort]: [
            SelectedValue,
            DropdownOption[],
            boolean
          ]) => {
            this.createOptions();
            if (sort) {
              this.sortOptions();
            }
          }
        )
    );
  }

  @Input()
  public set value(value: SelectedValue) {
    this._value = value;
    this.selectedValues.next(this._value);
  }

  public get value(): SelectedValue {
    return this._value;
  }

  @Input()
  public set availableOptions(available: DropdownOption[]) {
    this._available = available;
    this.availableValues.next(this._available);
  }

  public get availableOptions(): DropdownOption[] {
    return this._available;
  }

  @Input()
  public set sort(sort: boolean) {
    this._sort = sort;
    this.sortValues.next(this._sort);
  }

  public get sort() {
    return this._sort;
  }

  trackOptionsBy(index, option: DropdownOption) {
    return option.value;
  }

  ngOnChanges(): void {
    /**
     * This stuff has to be done in onChanges hook, because either
     * of the input properties "available" and "value" can be asynchronous !
     * and will make lots of problems with initial prefill, filtering and jada jada jada ....
     */
    this.filteredOptions = this.selectControl.valueChanges.pipe(
      startWith<string>(this.filterString),
      map((value) => (typeof value === "string" ? value : this.filterString)),
      map((filter) => this.filter(filter.trim())),
      tap((filtered: DropdownOption[]) => {
        if (
          this.dynamic &&
          this.filterString !== "" &&
          filtered.filter((f) => f.value === -2).length === 0 &&
          filtered.map((f) => f.label).filter((f) => f === this.filterString)
            .length === 0
        ) {
          filtered.unshift({ label: "", value: -2 });
        }
        this.noResults =
          (filtered.length === 1 &&
            (filtered[0].value === -1 || filtered[0].value === -2)) ||
          filtered.filter((f) => f.value === -2).length === 1;
      })
    );

    this.subscriptions.add(
      this._panelOpen.subscribe((open) => {
        // sometimes opening the panel leads to an empty list, so we have to force scroll back up to the top like in a deropdown w/o virtual scroll
        this.cdkVirtualScrollViewPort?.scrollToIndex(0);
        this.cdkVirtualScrollViewPort?.checkViewportSize();
        if (!open) {
          this.resetInput();
        }
        this.isPanelOpen = open;
      })
    );
  }

  ngOnInit(): void {
    /**
     * We do not always have a formControlName for this component. Sometimes (e.g. as filter dropdowns) we use
     * this as a simple component w/o any form-context, then we do not provide a formControlName or wrap it inside
     * a formGroup.
     */
    try {
      this.ngControl = this.inj?.get(NgControl);
      this.matcher = new customErrorStateMatcher(this.ngControl);
    } catch {
      this.ngControl = null;
    }

    if (!this.notFoundMessage) {
      // default found message if no input prop
      this.notFoundMessage = this.translateService.instant(
        "app.filter.not.in.list"
      );
    }

    if (this.ngControl) {
      /**
       * Since ngControl.control is undefined due to this known error -> https://github.com/angular/angular/issues/36197
       * we have to wrap it inside setTimeout to schedule a new macroTask in JS EventLoop.
       *
       * Since setTimeout is asynchronous we have to wait for the Promise to resolve.
       * We don't want to deal with Promises though, so we convert it to an Observable
       * we can easily access and manipulate !
       */
      const promise = new Promise<boolean>((resolve, reject) => {
        window.setTimeout(() => {
          // we want to know if the control is required, hence we have to access the validators.
          resolve(this.ngControl.control.hasValidator(Validators.required));
        }, 0);
      });

      /**
       * The single element of a required dropdown field is selected per default - see EC-3421
       */
      from(promise)
        .pipe(take(1))
        .subscribe((required: boolean) => {
          if (required && this.options.length === 1) {
            this.selectionChanged(this.options[0]);
          }
        });
    }
    this.fillInput = this.dynamic && !this.multiple;
  }

  /**
   * Answers if the user created option already exists or not.
   *
   * @return True if the option created by the user already exists, false otherwise
   */
  public isNewValue() {
    return this.noResults;
  }

  public setPanelState(open: boolean) {
    this._panelOpen.next(open);
  }

  public closeOpenPanel() {
    if (this.auto.panelOpen) {
      setTimeout(() => {
        this.auto.closePanel();
        // this.inputElement.nativeElement.blur();
      }, 0);
    }
  }

  public onTouched() {
    this.filterString = "";
    this._touched(true);
  }

  public displayFn = (): string =>
    this.fillInput && this.getSelectedOptions().length > 0
      ? this.getSelectedOptions()[0].label
      : "";

  public changeSelection(
    event: MatOptionSelectionChange,
    data: DropdownOption
  ) {
    if (event.isUserInput) {
      this.selectionChanged(data);
    }
  }

  public isOptionSelected(): boolean {
    return this.getSelectedOptions().length > 0 || this.selectControl.value;
  }

  /**
   * Gets the current selection or an empty list if nothing is selected
   *
   * @return A collection of selected {@link DropdownOption} elements
   */
  public getSelectedOptions(): DropdownOption[] {
    return this.options.filter((option) => option.selected);
  }

  /**
   * Updates the current selection by updating the {@link DropdownOption}s options according to the current selection
   * mode (single / multiple). <br/>This method always calls {@link updateValue} to update the control's current value
   * correspondingly. Both values must point to the same object ant therefore are synchronized.
   */
  public selectionChanged(option: DropdownOption): void {
    if (!option.disabled) {
      this.options.forEach((o) => {
        if (this.isSingleSelection()) {
          // Update the selection in the case of single selection
          o.selected = o.value === option.value;
        } else {
          if (o.value === option.value) {
            // Flip the selection in case of multiple selection
            o.selected = !option.selected;
          }
        }
      });
    }
    this.floatLabel = this.getSelectedOptions().length > 0 ? "always" : "auto";
    this.updateValue();
  }

  public resetSelection(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    this.resetInput();
    this.options.forEach((option: DropdownOption) => (option.selected = false));
    this.updateValue();
    this.closeOpenPanel();
  }

  /**
   * This method is invoked in cases where a programmatic update of the control's value must be intercepted in order
   * to update the view. Examples of a programmatic update include:
   * <ol>
   *   <li>initializing a form control value</li>
   *   <li>updating a form control value with patchValue or reset</li>
   * </ol>
   *
   *  (1) const answerCtl = new FormControl(0);
   *  <br/>(2) this.formGroup.get("answer").patchValue(42);
   *  <br/>(3) this.formGroup.get("answer").reset(21);
   *  <br/>
   *  The method is called by all clients using this component without passing a value of the "value" input property.
   *  Its implementation triggers an update of the selected value the same way the property accessor
   *  <code>public set value(value: SelectedValue)</code> is doing it.
   *  <br/>Note that when this method is called the value of the {@link _available} may have not settled, meaning that
   *  the selection will be updated when the collection of available options becomes available.
   */
  public writeValue(value: SelectedValue | null): void {
    this.value = value;
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    // Future use if custom validation is needed! At the moment we just use build-in Validator required and set styles accordingly !
    return null;
  }

  public registerOnChange(fn: (_: any) => void): void {
    this._change = fn;
  }

  public registerOnTouched(fn: (_: any) => void): void {
    this._touched = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    if (isDisabled) {
      this.selectControl.disable();
    } else {
      this.selectControl.enable();
    }
  }

  public addNewValueOnEnter(evt: Event) {
    evt.stopPropagation();
    if (this.dynamic && this.isNewValue()) {
      this.addNewValue();
    }
  }

  /**
   * Adds a new option to the collection of available options and to the current selection.
   *
   * <p>Newly created / added options are must be selected per default. In order to achieve that we make use of an
   * internal knowledge of how {@link selectionChanged} works
   * <p>In a **multiple selection mode** the selected value is
   * toggled which means that a newly created option will be de-selected which is not the desired behaviour. For this
   * reason the newly created option is set to false, relying on {@link selectionChanged} to toggle the selection.
   */
  public addNewValue() {
    const newVal = this.selectControl.value.trim();
    const newOption: DropdownOption = {
      label: newVal,
      value: newVal,
      selected: this.isSingleSelection(), // See the method's comment
      disabled: false,
    };
    this.availableOptions = [...this.availableOptions, newOption]; // triggers createOptions()
    this.selectionChanged(newOption);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public isResetSelectionVisible(): boolean {
    return (
      this.resetButton &&
      !this.isDisabled &&
      this.getSelectedOptions().length > 0
    );
  }

  public isSingleSelection(): boolean {
    return !this.multiple;
  }

  /**
   * Crates a <b>copy</b> of the initial available options and updates their state accordingly.
   * <p>This method has following responsibilities:
   * <ul>
   *   <li>resolving the option's label using the value of a {@link displayKey} input property</li>
   *   <li>updating the initial selected state of the drop down options</li>
   * </ul>
   */
  private createOptions() {
    if (this._available?.length > 0) {
      this.options = cloneDeep(this._available).map(
        (option: DropdownOption) => {
          // Overwrite the dropdown option's label using the value of the input property displayKey
          option.label = option[this.displayKey];
          // Use a case-sensitive search to find the selected elements because the type of the selection is any
          // and this is the best way to provide a universal equals() method.
          option.selected = JSON.stringify(this._value ?? []).includes(
            JSON.stringify(option.value)
          );
          return option;
        }
      );
    } else {
      this.options = [];
    }
  }

  private sortOptions() {
    this.options.sort((a: DropdownOption, b: DropdownOption) => {
      if (this.sortBy === DropdownSortByOption.ACTIVE) {
        if (!b.active && a.active) {
          return -1;
        } else if (!a.active && b.active) {
          return 1;
        }
      }
      if (this.sortBy === DropdownSortByOption.ASC) {
        return a.label.localeCompare(b.label);
      } else if (this.sortBy === DropdownSortByOption.DESC) {
        return b.label.localeCompare(a.label);
      }
    });
  }

  private resetInput() {
    this.selectControl.setValue(null);
  }

  /**
   * Answers the current selection. This method projects over {@link options} by filtering all elements
   * tagged as selected.
   *
   * @return A collection of selected option values or a single value.
   **/
  private getSelectedValue(): SelectedValue {
    const selected = this.getSelectedOptions().map(
      (option) => option.value as string
    );
    return this.isSingleSelection() ? selected[0] ?? null : selected;
  }

  /**
   * Updates the value of the control with the current selection. This method is called every time the selection has
   * changed - as a result of selecting one or more elements of {@link availableOptions} for example.
   *
   * <br/>Maintaining the selection state depends on both {@link value} and {@link availableOptions}.
   * Furthermore they redirect the updates to the event emitters {@link selectedValues} and {@link availableValues}
   * which then combined trigger the synchronization mechanism.
   */
  private updateValue(): void {
    this._value = this.getSelectedValue();
    this._change(this._value);
    this.setValid();
    this.cdRef.detectChanges();
    this.filterString = "";
  }

  private setValid() {
    this.isInValid = this.ngControl?.invalid && this.ngControl?.touched;
  }

  private filter(filter: string): DropdownOption[] {
    this.filterString = filter;
    if (filter.length > 0) {
      const filtered = this.options.filter(
        (option) =>
          option.label.toLowerCase().indexOf(filter.toLowerCase()) >= 0
      );
      if (filtered.length > 0) {
        return filtered;
      } else if (!this.dynamic) {
        return [{ label: "", value: -1 }];
      } else {
        return [{ label: "", value: -2 }];
      }
    } else {
      return this.options.slice();
    }
  }

  /**
   * Callback notified on view changes in order to update the model.
   * <br/>The method called by the forms API on **initialization** to update the model with the updated view value.
   * Other events like changes in the currently selected elements for example,  will not trigger this method.
   */
  private _change: (_: any) => void = (evt) => {
    this.change.emit(evt);
  };

  private _touched: (_: any) => void = () => { };
}
