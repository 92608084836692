<!-- TILE VIEW -->
<h4 *ngIf="!selectedSchadenTyp" style="margin-left: -3px; margin-top: 0px">{{ "schaden.schadenmeldung.schadentyp.title"
  | translate }}</h4>
<div *ngIf="!selectedSchadenTyp" fxLayout="row wrap" class="light flexContainer">
  <ng-container *ngFor="let group of getGroups()">
    <div class="tile form-box">
      <div class="header">
        <div class="iconwrapper">
          <lib-universal-icon color="primary" [iconKey]="getGroupIcon(group.id)"></lib-universal-icon>
        </div>
        <div class="titlewrapper">{{group.bezeichnung}}</div>
      </div>

      <div class="content">
        <ul>
          <!-- Something nice I found while looking for ways to avoid using getTooltip() twice (inside the button and icon) https://stackoverflow.com/a/51962431 -->
          <ng-container *ngFor="let option of group.options" [ngTemplateOutlet]="item"
            [ngTemplateOutletContext]="{ tooltip: option.info, option: option }"></ng-container>
          <ng-template #item let-option="option" let-tooltip="tooltip">
            <li [ngClass]="{ 'active' : isSelected(option)}">
              <button type="button" mat-button color="primary" (click)="optionSelected(option)" [matTooltip]="tooltip"
                matTooltipPosition="after">
                {{ option.bezeichnung }}
                <mat-icon *ngIf="tooltip" iconPositionEnd class="optionTooltip">info</mat-icon>
              </button>
            </li>
          </ng-template>
        </ul>
      </div>
    </div>
  </ng-container>
</div>

<!-- READONLY VIEW -->
<div fxLayout="row wrap" *ngIf="selectedSchadenTyp" class="selectedWrapper">
  <div class="selectedTyp">{{ "schaden.schadenmeldung.schadentyp.selected.title" | translate : {schadenTitle:
    selectedSchadenTyp.bezeichnung} }}</div>
  <button type="button" mat-button color="primary" (click)="setEditMode()"><mat-icon>edit</mat-icon> {{
    "schaden.schadenmeldung.formular.schadentyp.change.button.title" | translate }}</button>
</div>