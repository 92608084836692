import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { DoCheck, HostBinding, Injectable, Input, OnDestroy, Optional, Self } from "@angular/core";
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgControl, NgForm } from "@angular/forms";
import { CanUpdateErrorState, ErrorStateMatcher, mixinErrorState } from "@angular/material/core";
import { MatFormFieldControl } from "@angular/material/form-field";
import { Observable, Subject, Subscription } from "rxjs";
import { shareReplay, startWith } from "rxjs/operators";

/**
 * Super class for all inputs that delegate to a form group.
 * V: value type
 * F: names and types of the fields
 */


class AbstractDelegatingInputComponentBase {
  constructor(public _defaultErrorStateMatcher: ErrorStateMatcher,
              public _parentForm: NgForm,
              public _parentFormGroup: FormGroupDirective,
              public ngControl: NgControl,
              public stateChanges: Subject<void>) {
  }
}
// eslint-disable-next-line @typescript-eslint/naming-convention
const _AbstractDelegatingInputComponentBase = mixinErrorState(AbstractDelegatingInputComponentBase);

@Injectable()
export abstract class AbstractDelegatingInputComponent<V, F extends { [k: string]: any }> extends _AbstractDelegatingInputComponentBase implements OnDestroy,
  MatFormFieldControl<V>, ControlValueAccessor, CanUpdateErrorState, DoCheck {
  static nextId = 0;
  @HostBinding()
    id = `custom-input-${AbstractDelegatingInputComponent.nextId++}`;

  public readonly formGroup: UntypedFormGroup;

  private readonly _empty: boolean;
  public get empty(): boolean {
    return this._empty;
  }

  private changeSubscription: Subscription;
  private _required = false;
  private _disabled = false;
  private _placeholder: string;

  private _focused = false;

  /**
   * Injects all dependencies. Note to implementors: If you overwrite the constructor, you have to add the
   * Decorators on your constructor arguments.
   *
   * @param _defaultErrorStateMatcher Specifies when this component is in error state.
   * @param _parentForm The form
   * @param _parentFormGroup The form group we are in
   * @param ngControl The actual form control that this component is bound to.
   */
  constructor(
    _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    @Optional() @Self() ngControl: NgControl,
    @Optional() stateChanges: Subject<void>
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl, stateChanges);
    if (ngControl !== null) {
      ngControl.valueAccessor = this;
    }

    this.formGroup = new UntypedFormGroup(this.generateFormControls());

    this.changeSubscription = (this.formGroup.valueChanges as Observable<F>).pipe(
      startWith(this.formGroup.value as F),
      shareReplay(1)
    ).subscribe(change => {
      const convertedChange = this.formToValue(change);
      // When a value changes, the value is changed in the form control using this call
      this.onChange(convertedChange);
      // no onChange is called on us in this case. We re-trigger error checking ourselves, so that we are consistent.
      this.updateErrorState();
      // UpdateErrorState only calls this, when error state changed. We need to call every time, since value may have changed.
      this.stateChanges.next();
    });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.changeSubscription.unsubscribe();
  }

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngDoCheck() {
    if (this.ngControl) {
      // Angular components do it here => There seems to be no other way of subscribing to changes
      // Without this, highlighting as 'error' when going to the next stepper step without filling the form won't work.
      this.updateErrorState();
    }
  }

  get formGroupValue() {
    return this.formGroup.value as F;
  }

  @Input()
  public get value(): V | null {
    return this.formToValue(this.formGroupValue);
  }

  public set value(value: V | null) {
    const formValue = this.valueToForm(value);
    if (typeof formValue !== "object" || formValue === null) {
      throw new Error("formValue is not a valid object. Got: " + formValue);
    }
    this.formGroup.setValue(formValue);
    this.stateChanges.next();
    this.updateErrorState();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
    }
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this._required;
  }

  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  // TODO? @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty || this.errorState;
  }

  public get focused(): boolean {
    return this._focused;
  }

  public set focused(val: boolean) {
    if (this._focused && !val) {
      this.onTouched();
    }
    this._focused = val;
    this.stateChanges.next();
    this.updateErrorState();
  }

  setDescribedByIds(ids: string[]) {
    // NOP
  }

  onContainerClick(event: MouseEvent) {
    // NOP
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: V): void {
    this.value = obj;
  }

  protected onTouched = () => {
  };

  private onChange = (_: any) => {
  };

  protected abstract generateFormControls(): { [key in keyof F]: UntypedFormControl };

  protected abstract formToValue(value: F | null): V | null;

  protected abstract valueToForm(value: V | null): F;

}
