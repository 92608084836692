<mat-form-field [appearance]="appearance">
  <input [required]="required" matInput [formControl]="inputFormControl" />
    <mat-icon *ngIf="suffix?.iconkey && !suffix?.text" matSuffix>{{ suffix.iconkey }}</mat-icon>
    <mat-icon *ngIf="suffix?.iconkey && suffix?.text" matSuffix [matTooltip]="suffix.text">{{ suffix.iconkey }}</mat-icon>

  <mat-label style="margin-bottom: 12px">
    {{ label | translate }}
  </mat-label>
  <mat-error>{{
    error
    }}</mat-error>
</mat-form-field>