import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IconsModule } from "connect-frontend-components/icons";
import { MatInputModule } from "@angular/material/input";
import { OptionTilesComponent } from "./option-tiles.component";



@NgModule({
  declarations: [
    OptionTilesComponent
  ],
  imports: [
    CommonModule,
    IconsModule,
    MatInputModule
  ],
  exports: [
    OptionTilesComponent
  ]
})
export class OptionTilesModule { }
