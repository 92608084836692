import { AfterViewInit, ComponentRef, Directive, Input, OnDestroy, OnInit, ViewContainerRef } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { isEqual } from "lodash/isEqual";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { ClearIconComponentResetInputComponent } from "./clear-icon/clear-icon-component-reset-input.component";


/**
 * Allows to reset a directive
 */
@Directive({
  selector: "[libResetInput]",
  providers: [MatIcon]
})
export class ResetInputDirective implements OnInit, OnDestroy, AfterViewInit {

  /**
   * The value to reset the input to.
   */
  private _libResetInput: unknown = null;

  private clearAllComponent: ComponentRef<ClearIconComponentResetInputComponent>;

  private readonly subscriptions = new Subscription();

  constructor(
    private input: MatInput,
    private _viewContainer: ViewContainerRef  ) {
  }

  ngOnInit() {
    if (this.input.ngControl) {
      this.subscriptions.add(
        this.input.ngControl.valueChanges.pipe(
          distinctUntilChanged(isEqual),
        ).subscribe(value => {
          this.updateHiddenState();
        })
      );
    }
  }

  ngAfterViewInit() {
    // Adds
    this.clearAllComponent = this._viewContainer.createComponent(ClearIconComponentResetInputComponent);
    this.subscriptions.add(
      this.clearAllComponent.instance.iconClick.subscribe(() => {
        this.input.ngControl.control.setValue(this.defaultValue);
      })
    );

    this.updateHiddenState();
  }

  get defaultValue() {
    const defaultValue = this._libResetInput;
    return defaultValue;
  }

  get libResetInput(): unknown {
    return this._libResetInput;
  }

  @Input()
  set libResetInput(value: unknown) {
    // We need to use this setter and not ngDoChec => ngDoCheck ist just called way to often.
    this._libResetInput = value;
    this.updateHiddenState();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private updateHiddenState() {
    if (this.clearAllComponent) {
      let hidden = true;
      if (this.input.ngControl) {
        const defaultValue = this.defaultValue;
        const value = this.input.ngControl.value;
        hidden = !value || value === defaultValue;
      }
      this.clearAllComponent.instance.hidden = hidden;
      this.clearAllComponent.changeDetectorRef.detectChanges();
    }
  }


}
