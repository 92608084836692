import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AbstractSchadenMeldungFunction } from "../AbstractSchadenMeldungFunction";

@Component({
  selector: "lib-unterlagen-step",
  templateUrl: "./unterlagen-step.component.html",
  styleUrls: ["./unterlagen-step.component.scss"],
})
export class UnterlagenStepComponent
  extends AbstractSchadenMeldungFunction
  implements OnInit, OnDestroy {
  @Input()
    unterlagenFormGroup: UntypedFormGroup;

  constructor(protected translate: TranslateService) {
    super(translate);
  }

  ngOnInit(): void {}

  ngOnDestroy() {}

  getFormFieldErrorMessageComponent(key: string): string {
    const group = this.unterlagenFormGroup.get(key);
    return this.getFormFieldErrorMessage(group.errors);
  }
}
