<div
  class="optionTiles form-box"
  [ngClass]="{ disabled: disabled, error: hasError }"
>
  <h4 class="form-box-title">{{ label }}</h4>
  <div class="optionsWrapper">
    <div
      class="option form-box"
      *ngFor="let option of options$ | async"
      (click)="toggleSelected(option)"
      (keydown.enter)="toggleSelected(option)"
    >
      <div class="optionContent" [ngClass]="{ checked: option.selected }">
        <div class="optionIcon">
          <lib-universal-icon [iconKey]="option.iconKey"></lib-universal-icon>
        </div>
        <div class="optionLabel">{{ option.label }}</div>
      </div>
    </div>
  </div>
  <mat-error *ngIf="hasError"> {{ requiredError }} </mat-error>
</div>
