import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
// Internal dependencies
import { SafeHtmlModule, StripHtmlModule } from "connect-frontend-components/text-utils";
import { OptionDialogComponent } from "./option-dialog.component";
import { OptionDialogService } from "./option-dialog.service";

@NgModule({
  declarations: [
    OptionDialogComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    DragDropModule,
    MatButtonModule,
    MatDialogModule,
    SafeHtmlModule,
    StripHtmlModule
  ],
  exports:[
    OptionDialogComponent
  ],
  providers: [
    OptionDialogService
  ]
})
export class OptionDialogModule { }
