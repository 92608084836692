export class Adresse {
  public static readonly EMPTY = new Adresse("", "", "", "", "", "", "");

  constructor(
    public name: string,
    public strasse: string,
    public plz: string,
    public ort: string,
    public land: string,
    public telefon: string,
    public email: string
  ) {}

  public equals(other: Adresse): boolean {
    if (!other) {
      return false;
    }

    if (this === other) {
      return true;
    }

    return this.name === other.name &&
      this.strasse === other.strasse &&
      this.plz === other.plz &&
      this.ort === other.ort &&
      this.land === other.land &&
      this.telefon === other.telefon &&
      this.email === other.email;
  }
}
