import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IconsModule } from "connect-frontend-components/icons";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SchadenMeldungKachelComponent } from "./schaden-meldung-kachel.component";


@NgModule({
  declarations: [
    SchadenMeldungKachelComponent
  ],
  imports: [
    CommonModule,
    IconsModule,
    MatInputModule,
    MatButtonModule,
    FlexLayoutModule,
    TranslateModule,
    MatIconModule,
    MatTooltipModule
  ],
  exports: [
    SchadenMeldungKachelComponent
  ]
})
export class SchadenMeldungKachelModule { }
