import { AfterViewInit, Component, Injector, Input, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgControl, ValidationErrors, Validator } from "@angular/forms";
import { MatFormFieldControl } from "@angular/material/form-field";

import { BehaviorSubject, Subscription } from "rxjs";
import { Option } from "../interfaces";
import { TileOption } from "./option-tiles.interfaces";

interface ValueHolder {
  value: unknown;
}

@Component({
  selector: "lib-option-tiles",
  templateUrl: "./option-tiles.component.html",
  styleUrls: ["./option-tiles.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: OptionTilesComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: OptionTilesComponent
    },
    { provide: MatFormFieldControl, useExisting: OptionTilesComponent }
  ]
})
export class OptionTilesComponent implements ControlValueAccessor, Validator, AfterViewInit, OnInit, OnDestroy {

  @Input() label: string;
  @Input() multiple = false;
  @Input() requiredError?: string;

  @Input()
  public set availableOptions(available: Option[]) {
    this._available = available;
  }

  public get availableOptions(): Option[] {
    return this._available;
  }

  @Input()
  public set value(value: unknown) {
    setTimeout(() => {
      this.writeValue(value);
    }, 0);
  }

  public get value(): unknown {
    return this.getSelected().map((o: Option) => o.value);
  }

  /** The options exposed to the view */
  public options$: BehaviorSubject<TileOption[]> = new BehaviorSubject<TileOption[]>([]);

  public touched = false;
  public disabled = false;
  public hasError = false;

  private control: FormControl;
  private subscriptions: Subscription = new Subscription();
  /**
   * The collection of all available options passed by parent components for initialization. This collection will be
   * exposed to the view.
   */
  private _available: Option[] = [];
  private onChange = (selValues: unknown) => {};
  private onTouched = () => {};

  constructor(
    private injector: Injector,
  ) { }

  ngOnInit(): void {
    this.options$.next(this.createOptions(this._available));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);

    if (ngControl) { // otherwise component is missing form control-binding ...
      this.control = ngControl.control as FormControl;

      this.subscriptions.add(
        this.control.statusChanges.subscribe(() => {
          this.hasError = this.control.invalid && this.control.touched;
        })
      );
    }
  }

  // Use this method to update the view (DOM-Property) with the value (model) of a FormControl instance.
  // This method assumes that the FormControl value was already set on initialization or by using FormControl.setValue().
  // Thus in order to avoid infinite loops you should never call setValue() on a FormControl instance here.
  writeValue(val: unknown): void {
    this.updateSelected(val);
  }

  // Register a function used to update the form model when the view changes
  registerOnChange(callback: (_: any) => void): any {
    this.onChange = callback;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null; // maybe future use in case we need custom validator
  }

  public toggleSelected(selected: Option) {
    if (!this.disabled) {
      this.markAsTouched();
      // Create an updated copy of the original options cached by the BehaviourSubject
      const updated: TileOption[] = this.options$.value.map((option: TileOption) => this.updateSelection(option, selected));
      this.options$.next(updated); // Update the options with the new collection
      // Update the FormControl model (value) after The selection has changed
      const [first, ...rest] = this.getSelected().map((selOption: Option) => selOption.value);
      this.onChange(this.multiple ? [first, ...rest] : first);
    }
  }

  public getSelected(): Option[] {
    return this.options$.value.filter((option: Option) => option.selected);
  }

  /**
   * Creates a copy of the original option and updates its selected state depending on the selection mode (single / multiple)
   * of this component (single / multiple)
   */
  private updateSelection(option: TileOption, selected: Option): TileOption {
    const copy: TileOption = option.copy();
    if (option.equals(selected)) {
      copy.setSelected(true);
    } else if (!this.multiple) {
      copy.setSelected(false);  // In single selection mode all other options are de-selected
    }
    return copy;
  }

  /**
   * Crates a <b>copy</b> of the initial available option collection and updates their state accordingly.
   * <p>This method has the following responsibilities:
   * <ul>
   *   <li>updating the initial <b>selected</b> state of the options</li>
   * </ul>
   */
  private createOptions(options: Option[]): TileOption[] {
    const copies: TileOption[] = [];
    if (options) {
      options.forEach((option: Option): void => {
        const copy: TileOption =  new TileOption(option.label, option.value);
        copy.setIconKey(option.iconKey);
        copies.push(copy);
      });
    }
    return copies;
  }

  private updateSelected(selected: unknown): void {
    let optionsToSelect: Option[] = [];
    if (selected) {
      if (Array.isArray(selected) && selected.length > 0) {
        const isSelected = (option: Option): boolean => selected.find((sel: ValueHolder): boolean => sel.value === option.value) !== null;
        optionsToSelect = this.availableOptions.filter((o: Option): boolean => isSelected(o));
      } else {
        optionsToSelect = this.availableOptions.filter((o: Option) => o.value === selected);
      }
    }
    optionsToSelect.forEach((sel: Option) => this.toggleSelected(sel));
  }
}
