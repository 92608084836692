<div fxLayout="column">
  <!-- <div *ngIf="kundennummerFormControl !== null" style="margin-bottom:1rem">
    <h2 class="maintitle">{{
      "schaden.schadenmeldung.formular.versicherungsnehmer.kundennummer.label" | translate }}</h2>
    <lib-input-text-feld [error]="getFormFieldErrorMessageComponent('kundenNummer')"
      [inputFormControl]="kundennummerFormControl" label="schadenmeldung.kundenNummer"></lib-input-text-feld>
  </div> -->
  <div *ngIf="versicherungsnehmerFormControl !== null">
    <div fxLayout="column">
      <div class="formStepHeadline">
        <h4 class="fieldtitle">
          {{ "schaden.schadenmeldung.formular.versicherungsnehmer.anschrift.title" | translate }}
        </h4>
        <mat-icon [matTooltip]="infoToolTip">info</mat-icon>
      </div>

      <lib-input-text-feld [error]="getFormFieldErrorMessageComponent('kundenNummer')"
        [inputFormControl]="kundennummerFormControl" label="schadenmeldung.kundenNummer"
        [suffix]="helpSuffix"></lib-input-text-feld>

      <lib-address-input [borderAndTitle]="false" [formControl]="versicherungsnehmerFormControl"
        [requiredForFields]="[true, true, true, true, false, false]" [visible]="[true, true, true, true, false, false]"
        fxFlex></lib-address-input>
    </div>
  </div>
</div>
