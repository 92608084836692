<div fxLayout="column" [formGroup]="bankFormGroup">
  <div class="formStepHeadline">
    <h4 class="fieldtitle">Wie lauten die Bankdaten des Zahlungsempfängers?</h4>
    <mat-icon [matTooltip]="infoToolTip">info</mat-icon>
  </div>

  <div *ngIf="bankFormGroup.get('kontoinhaber') !== null">
    <lib-input-text-feld id="kontoinhaber" [error]="getFormFieldErrorMessageComponent('kontoinhaber')"
      [inputFormControl]="bankFormGroup.get('kontoinhaber')" label="schadenmeldung.kontoinhaber"></lib-input-text-feld>
  </div>

  <div *ngIf="bankFormGroup.get('iban') !== null">
    <lib-input-text-feld id="iban" [error]="getFormFieldErrorMessageComponent('iban')"
      [inputFormControl]="bankFormGroup.get('iban')" label="schadenmeldung.iban"></lib-input-text-feld>
  </div>

  <mat-checkbox *ngIf="bankFormGroup.get('vorsteuerabzugberechtigt') !== null" id="vorsteuerabzugberechtigt"
    (change)="showVorsteuerabzug()" [formControl]="bankFormGroup.get('vorsteuerabzugberechtigt')" fxFlex
    style="margin-bottom: 0.75em">
    {{ "schadenmeldung.vorsteuerabzugberechtigt" | translate }}
  </mat-checkbox>

  <div *ngIf="
      bankFormGroup.get('vorsteuerabzug') !== null &&
      bankFormGroup.get('vorsteuerabzugberechtigt').value
    ">
    <!-- <lib-input-text-feld id="vorsteuerabzug" [error]="getFormFieldErrorMessageComponent('vorsteuerabzug')"
      [inputFormControl]="bankFormGroup.get('vorsteuerabzug')"
      label="schadenmeldung.vorsteuerabzug"></lib-input-text-feld> -->

    <mat-form-field appearance="outline">
      <mat-label>{{"schadenmeldung.vorsteuerabzug" | translate}}</mat-label>
      <input formControlName="vorsteuerabzug" matInput type="number" step="1" max="100" autocomplete="off">
    </mat-form-field>
  </div>
</div>