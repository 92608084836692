import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { StackedIconComponent } from "./stacked-icon/stacked-icon.component";
import { UniversalIconRegistryService } from "./universal-icon-registry.service";
import { UniversalIconComponent } from "./universal-icon/universal-icon.component";

@NgModule({
  declarations: [
    StackedIconComponent,
    UniversalIconComponent
  ],
  providers: [
    UniversalIconRegistryService
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule
  ],
  exports: [
    StackedIconComponent,
    UniversalIconComponent
  ]
})
export class IconsModule {}
