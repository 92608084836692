import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { FieldConfigInputDto } from "connect-frontend-service";
import { AbstractSchadenMeldungFunction } from "../AbstractSchadenMeldungFunction";

@Component({
  selector: "lib-bankverbindung-step",
  templateUrl: "./bankverbindung-step.component.html",
  styleUrls: ["./bankverbindung-step.component.scss"]
})
export class BankverbindungStepComponent extends AbstractSchadenMeldungFunction {

  @Input()
    bankFormGroup: UntypedFormGroup;

  @Input()
    isUserAnoym: boolean;

  @Input()
    bankverbindungFragenConfig: FieldConfigInputDto[];

  constructor(translate: TranslateService,
  ) {
    super(translate);
  }

  public infoToolTip = "Hier können Sie die Bankverbindung eintragen, an die die Entschädigung ausgezahlt werden soll. Das kann der Versicherungsnehmer selbst, aber auch ein Anspruchsteller sein.";

  getFormFieldErrorMessageComponent(key: string): string {
    const group = this.bankFormGroup.get(key);
    return this.getFormFieldErrorMessage(group.errors, null, this.getMaxLength(key));
  }

  showVorsteuerabzug() {
    if (this.bankFormGroup.get("vorsteuerabzugberechtigt")) {
      this.bankFormGroup.get("vorsteuerabzug").enable();
    } else {
      this.bankFormGroup.get("vorsteuerabzug").disable();
    }
  }

  getMaxLength(keyName: string): number {
    return this.bankverbindungFragenConfig?.find(obj => obj?.key === keyName)?.maxLength || null;
  }

}
