import { EventEmitter, Injectable, OnDestroy, Output } from "@angular/core";
import { BehaviorSubject, catchError, delay, first, forkJoin, Observable, of, Subscription } from "rxjs";
import { TextParser, TextParserSettings } from "connect-frontend-components/text-utils";
import { II18NSupport, ITextBausteinSupport, insertVariableIcon, selectedTextIcon } from "./interfaces";


@Injectable({
  providedIn: "root"
})
export class EditorSettingsService implements OnDestroy {

  @Output() insertTextbaustein = new EventEmitter<any>();
  @Output() insertVariable = new EventEmitter<any>();
  @Output() getSelectedText = new EventEmitter<any>();

  private loaded = new BehaviorSubject(false);
  private subscriptions: Subscription = new Subscription();

  constructor(
    private i18nSupport: II18NSupport,
    private textBausteinSupport: ITextBausteinSupport,
  ) {
    const langProvider$: Observable<string> = i18nSupport.getLanguage().pipe(
      catchError((err) => {
        console.log("Error in i18nSupport.getLanguage() --> ", err);
        return of<string>("de");
      })
    );

    const accessPermsProvider$: Observable<boolean> = textBausteinSupport.canUseTextBaustein().pipe(
      catchError((err) => {
        console.log("Error in textBausteinSupport.canUseTextBaustein() --> ", err);
        return of<boolean>(false);
      })
    );

    const toolbarExtensions = " pagebreak addtextbaustein";
    const toolbarVariablesExtension = " addvariables";

    // Wait for all observables part of the collection passed to forkJoin to emit and complete
    // then emit an array of values and complete
    this.subscriptions.add(
      forkJoin([langProvider$, accessPermsProvider$]).pipe(
        first(),
        delay(10) // start macroTask to prevent hidden pagebreak and textbaustein tinymce buttons
      ).subscribe(([lang, access]) => {
        this._settings.language = lang;
        if (access) {
          this._settings.setup = (editor) => {
            editor.ui.registry.addIcon("variableIcon", insertVariableIcon);
            editor.ui.registry.addIcon("selectedTextIcon", selectedTextIcon);

            editor.ui.registry.addButton("addtextbaustein", {
              text: "",
              tooltip: "Textbaustein einfügen",
              icon: "paste-text",
              onAction: () => this.insertTextbaustein.emit(editor)
            });

            editor.ui.registry.addButton("addvariables", {
              text: "",
              tooltip: "Variable einfügen",
              icon: "variableIcon",
              onAction: () => this.insertVariable.emit(editor)
            });


            editor.ui.registry.addButton("textbausteinfromselection", {
              text: "",
              disabled: true,
              tooltip: "Textbaustein aus Selektion erstellen",
              icon: "selectedTextIcon",
              onAction: () => this.getSelectedText.emit(editor),
              onSetup: (buttonApi) => {
                editor.on("NodeChange", () => {
                  buttonApi.setEnabled(editor.selection.getContent().length > 0);
                });
              }
            });
          };
        }
        this.loaded.next(true);
      })
    );
  }

  /**
   * Answers if the configuration of the editor settings has completed.
   * <p>Components making use of this service (TinyMatFormControl being the most prominent example) may desire
   * to wait until the editor's settings a completely configured before displaying itself. This method should facilitate
   * these attempts.
   *
   * @return An observable that emits true if the configuration of the editor settings has completed, or false otherwise
   */
  public get isConfigCompleted(): Observable<boolean> {
    return this.loaded.asObservable();
  }

  // TODO: All components that need parser settings MUST not be forced to instantiate this service - currently this
  //  is the case which makes text parser configuration more complicated then is can be. Have a look at
  //  SafeHtmlTextSettings that shows one option of how configuration can be done
  /**
   * Those are the allowed styles / tags for the tinymce editor.
   *
   * @see SafeHtmlTextSettings
   */
  public readonly textParserSettings: TextParserSettings = {
    whiteListTags: ["a", "div", "p", "b", "i", "ul", "ol", "li", "s", "span", "strong", "sub", "sup", "em", "br", "table", "tbody", "tr", "td", "u", "caption", "img"],
    whiteListAttrs: ["href", "target", "data-mce-bogus", "border", "colspan", "style", "class", "src", "alt", "width", "height"],
    whiteListStyles: ["text-decoration", "text-align", "list-style-type", "background-color", "border", "border-style", "border-color", "border-width", "border-collapse", "width", "color", "font-size"],
    openLinksInNewTab: true
  };

  /**
   * Those are the allowed styles / tags for the Textbaustein-Auswahl Dialog.
   *
   * @see SafeHtmlTextSettings
   */
  public readonly textBausteinDisplaySettings: TextParserSettings = {
    whiteListTags: ["div", "p", "b", "i", "s", "ul", "ol", "li", "span", "strong", "em", "br", "table", "tr", "td", "thead", "tbody", "hr", "sup", "sub", "u"],
    whiteListAttrs: ["data-mce-bogus", "style", "border", "cellpadding", "cellspacing", "class"],
    whiteListStyles: ["color", "text-align", "list-style-type", "color", "width", "background-color", "border", "border-color", "border-width", "border-collapse", "font-size"],
    openLinksInNewTab: false
  };

  /**
   * Those are the allowed styles / tags for preview in Akte / Termin / Benachrichtigungen.
   *
   * @see SafeHtmlTextSettings
   */
  public readonly textParserSettingsForPreview: TextParserSettings = {
    whiteListTags: ["a", "div", "p", "b", "i", "ul", "ol", "li", "span", "strong", "em", "br", "table", "tr", "td", "thead", "th", "tbody", "hr", "sup", "sub", "s", "u"],
    whiteListAttrs: ["style", "border", "href", "target", "cellpadding", "cellspacing"],
    whiteListStyles: ["text-decoration", "text-align", "list-style-type", "color", "width", "background-color", "border", "border-color", "border-width", "border-collapse", "font-size"]
  };

  /** language packs are stored in shared-scripts/tinymce/languages
   * note that package filename has to match the language-property !
   * https://www.tiny.cloud/docs-4x/configure/localization/#language
   */
  private _settings = {
    language: null, // we set language by loggedInUser preferences.
    menubar: false,
    branding: false,
    statusbar: false,
    format_empty_lines: true,
    base_url: "/tinymce",
    suffix: ".min",
    plugins: ["lists", "advlist", "pagebreak", "table"],
    browser_spellcheck: true,
    // See EC-3439: We only allow some specific list types, to make PDF conversion easier.
    advlist_bullet_styles: "circle,disc,square",
    advlist_number_styles: "default,lower-alpha",
    content_style: "p { margin: 0; }",
    forced_root_block: "div",
    table_use_colgroups: false,
    invalid_elements: "a", // we use this to prevent anchors in the editor (and the bookmark symbols), we still are able to display them in the text by allow "a" tag in whiteListTags of Textparser
    formats: {
      underline: { inline: "u", exact: true },
      strikethrough: { inline: "s", exact: true }
    },
    paste_postprocess: (plugin, args) => {
      const node = args.node as HTMLElement;
      const textParser = new TextParser();
      textParser.settings = this.textParserSettings;
      const html = node.innerHTML;
      node.innerHTML = "";
      textParser.parse(html, node);
    },
    paste_as_text: true,
    table_default_styles: { width: "100%", "border-color": "#555555", "border-collapse": "collapse" },
    table_default_attributes: {
      border: 1 // EC-5422 needed for TD having borders as well, we cant set TD CSS borders here ...
    },
    font_size_input_default_unit: "pt",
    font_size_formats: "7pt 8pt 9pt 10pt 11pt 12pt 13pt 14pt 18pt 20pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
    setup: null,
    pagebreak_separator: "<eccpagebreak/>"
  };

  private _settingsForTenantInfoField = {
    menubar: false,
    branding: false,
    statusbar: false,
    plugins: ["lists", "advlist", "link"],
  };

  private _toolbar = "fontsizeinput bold italic underline strikethrough superscript subscript forecolor numlist bullist table alignleft aligncenter alignright alignjustify";

  private _toolbarForTenantInfoField = "bold italic underline alignleft aligncenter alignright alignjustify bullist numlist link ";

  public get settingsForTenantInfoField() {
    return this._settingsForTenantInfoField;
  }

  public get toolbarForTenantInfoField() {
    return this._toolbarForTenantInfoField;
  }

  // we need an explicit extended toolbar because sometimes
  // we do not want e.g. Textbaustein button in editor toolbar
  // even if the user has the rights to see it.
  private _extendedToolbar = this._toolbar + " pagebreak textbausteinfromselection addtextbaustein addvariables ";
  private _extendedToolbarWithVaribles = this._toolbar + " addvariables";
  private _menubar = "file edit insert view format table tools help";

  public get settings() {
    return this._settings;
  }

  public get toolbar() {
    return this._toolbar;
  }

  public get extendedToolbar() {
    return this._extendedToolbar;
  }

  public get extendedToolbarWithVaribles() {
    return this._extendedToolbarWithVaribles;
  }

  public get menubar() {
    return this._menubar;
  }


  // ##### ##### Special Editor Settings for cockpit update-infos ##### #####
  public get updateInfoSettings() {
    const settings = {
      ...this._settings,
      plugins: ["lists", "advlist", "pagebreak", "table", "link", "image"],
      invalid_elements: null, // we have to allow "a" because of link plugin
      paste_data_images: true,
      images_upload_handler: (blobInfo) => new Promise((resolve, reject) => {
        const base64Data = "data:" + blobInfo.blob().type + ";base64," + blobInfo.base64();
        resolve(base64Data);
        reject("Error occurred during image upload!");
      })



    };
    return settings;
  }

  public get updateInfoToolbar() {
    let toolbar = this._toolbar;
    toolbar += " link image ";
    return toolbar;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public get settingsForBriefeditor() {
    const settings = {
      ...this._settings
    };
    settings.forced_root_block = "div";
    return settings;
  }
}
