<lib-field-adresse *ngIf="field.inputType === 'adresse'" [config]="field" [formGroup]="formGroup" [attr.data-field-id]="field.key"></lib-field-adresse>
<lib-field-checkbox *ngIf="field.inputType === 'checkbox'" [config]="field" [formGroup]="formGroup" [attr.data-field-id]="field.key"></lib-field-checkbox>
<lib-field-combobox *ngIf="field.inputType === 'combobox'" [config]="field" [formGroup]="formGroup" [attr.data-field-id]="field.key"></lib-field-combobox>
<lib-field-option-tiles *ngIf="field.inputType === 'list'" [config]="field" [formGroup]="formGroup" [attr.data-field-id]="field.key"></lib-field-option-tiles>
<lib-field-date *ngIf="field.inputType === 'date'" [config]="field" [formGroup]="formGroup" [attr.data-field-id]="field.key"></lib-field-date>
<lib-field-headline *ngIf="field.inputType === 'headline'" [config]="field" [formGroup]="formGroup" [attr.data-field-id]="field.key"></lib-field-headline>
<lib-field-input *ngIf="field.inputType === 'input'" [config]="field" [formGroup]="formGroup" [attr.data-field-id]="field.key"></lib-field-input>
<lib-field-input-numeric *ngIf="field.inputType === 'input_numeric'" [config]="field" [formGroup]="formGroup" [attr.data-field-id]="field.key"></lib-field-input-numeric>
<lib-field-input-area *ngIf="field.inputType === 'input_area'" [config]="field" [formGroup]="formGroup" [attr.data-field-id]="field.key"></lib-field-input-area>
<lib-field-kennzeichen *ngIf="field.inputType === 'kennzeichen'" [config]="field" [formGroup]="formGroup" [attr.data-field-id]="field.key"></lib-field-kennzeichen>
<lib-field-hinweis *ngIf="field.inputType === 'hinweis'" [config]="field" [formGroup]="formGroup" [attr.data-field-id]="field.key"></lib-field-hinweis>
<lib-field-input-big-decimal *ngIf="field.inputType === 'input_big_decimal'" [config]="field" [formGroup]="formGroup" [attr.data-field-id]="field.key"></lib-field-input-big-decimal>
<lib-field-time *ngIf="field.inputType === 'time'" [config]="field" [formGroup]="formGroup" [attr.data-field-id]="field.key"></lib-field-time>

