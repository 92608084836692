import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AbstractSchadenMeldungFunction } from "../AbstractSchadenMeldungFunction";
import { InputTextSufix } from "../input-text-feld/input-text-feld.component";

@Component({
  selector: "lib-versicherungsnehmer-step",
  templateUrl: "./versicherungsnehmer-step.component.html",
  styleUrls: ["./versicherungsnehmer-step.component.scss"]
})
export class VersicherungsnehmerStepComponent extends AbstractSchadenMeldungFunction implements OnInit {

  @Input()
    versicherungsnehmerFormControl: UntypedFormControl;

  @Input()
    kundennummerFormControl: UntypedFormControl;

  constructor(private translateService: TranslateService) {
    super(translateService);
  }

  ngOnInit(): void {
  }

  public infoToolTip = this.translateService.instant("schaden.schadenmeldung.formular.versicherungsnehmer.kundennummer.title.hint");

  public helpSuffix: InputTextSufix = {
    text: this.translateService.instant("schaden.schadenmeldung.formular.versicherungsnehmer.kundennummer.hint"),
    iconkey: "help"
  };

  getFormFieldErrorMessageComponent(key: string): string {
    return this.getFormFieldErrorMessage(this.kundennummerFormControl.errors);
  }


}
