import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { DropdownOption } from "connect-frontend-components/inputs";

@Component({
  selector: "lib-field-option-tiles",
  templateUrl: "./field-option-tiles.component.html",
  styleUrls: ["./field-option-tiles.component.scss"]
})
export class FieldOptionTilesComponent implements OnInit {

  /** The field metadata together with its initial collection of values. */
  @Input()
  public config: any; // TODO get FieldConfigOptionTilesDto from server;

  /** The form group the field control managed by this component is part of. */
  @Input()
  public formGroup: UntypedFormGroup;

  public availableValues: DropdownOption[];

  constructor() {

  }

  ngOnInit(): void {
    // Retrieve the initial values in order to create elements (tiles)
    this.availableValues = this.config.values.map(
      // TODO get the right Type Dto
      (value: any) => ({ id:value.id, label: value.bezeichnung, value: value.id, iconKey: value.iconKey })
    );
  }



  private isDisabled(itemId: string): boolean {
    const value = this.formGroup.get(this.config.key).value;
    const currentlySelected = Array.isArray(value) ? value : [value];

    const exclusions = currentlySelected
      .map(selectedId => this.config.values.find(it => it.id === selectedId))
      .filter(it => it)
      .map(it => it.exclusions)
      .reduce((a, b) => [...a, ...b], []);
    return exclusions.includes(itemId);
  }

}
