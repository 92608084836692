import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormGroup, ValidationErrors } from "@angular/forms";
import { MatFormFieldControl } from "@angular/material/form-field";
import { IdBezeichnungWithRefDto, SchadenGruppe, SchadenGruppeDto } from "connect-frontend-service";

@Component({
  selector: "lib-schaden-meldung-kachel",
  templateUrl: "./schaden-meldung-kachel.component.html",
  styleUrls: ["./schaden-meldung-kachel.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SchadenMeldungKachelComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: SchadenMeldungKachelComponent
    },
    { provide: MatFormFieldControl, useExisting: SchadenMeldungKachelComponent }
  ]
})
export class SchadenMeldungKachelComponent implements ControlValueAccessor, OnInit {

  @Output() public selectionChanged: EventEmitter<IdBezeichnungWithRefDto> = new EventEmitter<IdBezeichnungWithRefDto>();

  @Input() public model: SchadenGruppeDto[];
  @Input() public formGroup: UntypedFormGroup;
  @Input() public selectedSchadenTyp: IdBezeichnungWithRefDto;

  @Input() public set value(value: IdBezeichnungWithRefDto) {
    setTimeout(() => {
      this.writeValue(value);
    }, 0);
  }

  public get value(): IdBezeichnungWithRefDto {
    return this._value;
  }

  public touched = false;
  public disabled = false;
  public hasError = false;

  private iconByGroupName: { [index: string]: string } = {};
  private _value: IdBezeichnungWithRefDto | null;

  private onChange = (value) => { };
  private onTouched = () => { };

  constructor() {
    this.iconByGroupName[SchadenGruppe.GEB_INVENTAR_BU.toLowerCase()] = "broken_house";
    this.iconByGroupName[SchadenGruppe.HAFTPFLICHT.toLowerCase()] = "broken_glass";
    this.iconByGroupName[SchadenGruppe.TECHNISCH.toLowerCase()] = "machinery";
    this.iconByGroupName[SchadenGruppe.KFZ.toLowerCase()] = "car_collision_outlined";
    this.iconByGroupName[SchadenGruppe.WEITERE.toLowerCase()] = "bolt";
    this.iconByGroupName[SchadenGruppe.SONSTIGES.toLowerCase()] = "question_mark";
  }

  ngOnInit(): void {
  }

  public isSelected(option: IdBezeichnungWithRefDto): boolean {
    return JSON.stringify(this.selectedSchadenTyp) === JSON.stringify(option);
  }

  public getGroups(): SchadenGruppeDto[] {
    return this.model;
  }

  public getGroupIcon(groupName: string): string {
    return this.iconByGroupName[groupName];
  }

  public getCurrentId(option: IdBezeichnungWithRefDto): string {
    return option.id !== null && option.foreignId === null ? option.id : option.foreignId;
  }

  public optionSelected(option: IdBezeichnungWithRefDto): void {
    if (!this.disabled) {
      this.markAsTouched();
      this.writeValue(option);
      this.onChange(option);
    }
  }

  writeValue(val: IdBezeichnungWithRefDto | null): void {
    this._value = val ? val : null;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null; // maybe future use in case we need custom validator
  }

  setEditMode() {
    this.markAsTouched();
    this.onChange(this.value);
  }
}
