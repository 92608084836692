import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import {
  SchadenMeldenController,
  SchadenMeldenControllerClient,
  SchadenmeldungAntwortDto,
  SchadenmeldungFormularDto
} from "../dto-interfaces/connect-webservice";
import { FormDataBuilder } from "./form-data-builder";
import { HttpRestAdapterService } from "./http-rest-adapter.service";

@Injectable({
  providedIn: "root"
})
export class SchadenmeldungService extends SchadenMeldenControllerClient {

  private readonly apiUrl: string;
  private readonly httpRestClient: HttpClient;
  private readonly schadenMeldenClient: SchadenMeldenController;

  constructor(
    httpAdapter: HttpRestAdapterService,
    httpClient: HttpClient,
    @Inject("apiUrl") apiUrl: string,
  ) {
    super(httpAdapter);
    this.httpRestClient = httpClient;
    this.apiUrl = apiUrl;
    this.schadenMeldenClient = new SchadenMeldenControllerClient(httpAdapter);
  }

  public getFormularFragen(schadenTypId: string, indiv: string): Observable<SchadenmeldungFormularDto> {
    const params: HttpParams = new HttpParams().set("schadenTypId", schadenTypId).set("indiv", indiv);
    return this.schadenMeldenClient.getSchadenmeldungFormular({ schadenTypId, indiv });
  }

  public dezentraleSchadenmeldungExists(uuid: string): Observable<boolean> {
    if (uuid) {
      const params = new HttpParams().set("uuid", uuid.toString());
      return this.schadenMeldenClient.getDezentraleSchadenmeldungExists({ uuid: uuid.toString() });
    } else {
      return of(true);
    }
  }

  public sendSchadenmeldung(meldung: any, docs: File[]): Observable<SchadenmeldungAntwortDto> {
    let files: File[] = docs ?? [];
    if (files.length > 0) {
      files = [...files].sort((f1: File, f2: File) => f1.name.localeCompare(f2.name));
    }
    const formData: FormData = FormDataBuilder.newInstanceForSchadenMeldung(meldung, files).build();
    return this.httpRestClient.post<SchadenmeldungAntwortDto>(`${this.apiUrl}internal/schadenmeldung`, formData);
  }
}
