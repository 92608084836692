import { Option } from "../interfaces";

export class TileOption implements Option {

  public iconKey: string;
  public selected = false;

  constructor(public label: string, public value: unknown) {}

  public setIconKey(iconKey: string): void {
    this.iconKey = iconKey;
  }

  public getIconKey(): string {
    return this.iconKey;
  }

  public isSelected(): boolean {
    return this.selected;
  }

  public setSelected(sel: boolean): void {
    this.selected = sel;
  }

  public toggleSelected() {
    this.selected = !this.selected;
  }

  public equals(other: Option): boolean {
    return other ? other.value === this.value : false;
  }

  public copy(): TileOption {
    const copy: TileOption =  new TileOption(this.label, this.value);
    copy.setSelected(this.selected);
    copy.setIconKey(this.iconKey);
    return copy;
  }
}
