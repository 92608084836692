import { Component, Inject, OnInit } from "@angular/core";
import { TextParserSettings } from "connect-frontend-components/text-utils";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogCommand } from "./dialog-command";

export interface OptionDialogData {
  title: string;
  msg: string;
  actions: DialogCommand[];
}

@Component({
  selector: "lib-option-dialog",
  templateUrl: "./option-dialog.component.html",
  styleUrls: ["./option-dialog.component.scss"]
})
export class OptionDialogComponent implements OnInit {

  public settings: TextParserSettings = {
    whiteListTags: ["div", "p", "b", "i", "ul", "ol", "li", "span", "strong", "br"]
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: OptionDialogData,
    public dialogRef: MatDialogRef<OptionDialogComponent>
  ) {}

  ngOnInit(): void { }
}
