<div>
  <h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ modalData.title }}</h2>
  <mat-dialog-content style="white-space: pre-line;">
    <lib-safe-html [html]="modalData.msg" [settings]="settings">
    </lib-safe-html>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <ng-container *ngFor="let action of modalData.actions">
      <ng-container *ngIf="action.leaveOpen; then leaveOpen; else closeIt"></ng-container>
      <ng-template #leaveOpen>
        <button mat-button (click)="action.callback()">{{ action.title }}</button>
      </ng-template>
      <ng-template #closeIt>
        <button mat-button mat-dialog-close (click)="action.callback()">{{ action.title }}</button>
      </ng-template>
    </ng-container>
  </mat-dialog-actions>
</div>