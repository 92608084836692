import { Component, Input, OnChanges } from "@angular/core";
import { IndicatorStep } from "../interfaces";


@Component({
  selector: "lib-step-indicator",
  templateUrl: "./step-indicator.component.html",
  styleUrls: ["./step-indicator.component.scss"]
})
export class StepIndicatorComponent implements OnChanges {

  @Input() steps: IndicatorStep[];

  @Input() activeStep: number;

  constructor(

  ) {
  }
  ngOnChanges(): void {
    this.scrollActiveIntoView(this.activeStep);
  }

  private scrollActiveIntoView(stepId: number) {
    const container = document.getElementById("step-indicator-container");
    const el = document.getElementById(`step-${stepId}`);
    const left = stepId > 1 ? el?.offsetLeft - 10 : 0;
    container.scrollTo({ left, behavior: "smooth" });
  }




}
