import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
// Internal dependencies
import { StripHtmlPipe } from "connect-frontend-components/text-utils";
import { DomSanitizer } from "@angular/platform-browser";
import { OptionDialogComponent } from "./option-dialog.component";
import { DialogCommand, DialogDimensions, OptionalDialogDimensions } from "./dialog-command";

export interface OrderedDialogCommand extends DialogCommand {
  order: number;
}

interface DialogData {
  title: string;
  msg: string;
  actions: OrderedDialogCommand[];
}

/**
 * The structure of the configuration data expected by a modal dialog created by {@link MatDialog}.
 * */
interface DialogConfig {
  data: DialogData;
  width: string;
  height: string;
  minWidth?: string;
  minHeight?: string;
}

/**
 * I offer an understandable protocol for creating modal dialog configuration {@link DialogConfig} instances.
 * */
export class ConfigBuilder {
  private title: string;
  private message: string;
  private commands: OrderedDialogCommand[] = [];
  private width?: string;
  private height?: string;
  private minWidth?: string;
  private minHeight?: string;

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private stripHtmlPipe: StripHtmlPipe
  ) {}

  /**
   * Creates the dialog's title.
   *
   * @param titleKey The translation (i18n) key for the dialog's title
   * @return The builder object
   * */
  public withTitle(titleKey: string) {
    this.title = this.translateSanitized(titleKey);
    return this;
  }

  /**
   * Appends a single line to the dialog's message.
   *
   * @param msgKey The translation (i18n) key for this dialog's message
   * @return The builder object
   * */
  public withMessage(msgKey: string) {
    this.message = this.translateSanitized(msgKey);
    return this;
  }

  public withDimensions(w: string, h: string, minW?: string, minH?: string) {
    this.width = this.translateSanitized(w);
    this.height = this.translateSanitized(h);
    this.minWidth = minW ? this.translateSanitized(minW) : undefined;
    this.minHeight = minH ? this.translateSanitized(minH) : undefined;
    return this;
  }

  /**
   * Appends an i18n string mapped by its key to the modal dialog's message on a new line.
   *
   * @param msgKey The translation (i18n) key to the i18n file
   * @param params An optional object containing message parameter bindings.
   * Each parameter binding is a (name, value) pair expected by the message resolved by the msgKey.
   * Each param value is checked for a HTML/JavaScript content and sanitized correspondingly
   * @return The builder object
   */
  public withNextLine(msgKey: string, params?: Record<string, string>) {
    const newMsg = this.translateSanitized(msgKey, params);
    this.message = this.message ? `${this.message} <p>${newMsg}</p>` : `${newMsg}`;
    return this;
  }

  public withOption(label: string, cmd: () => void) {
    this.commands.push({ order: this.commands.length + 1, title: this.translateSanitized(label), callback: cmd });
    return this;
  }

  /**
   * Adds an "Yes" option to this dialog.
   *
   * @param cmd An (optional) action / command to be executed when the user confirmed with "Yes"
   * @return The builder object
   */
  public withOptionYes(title?: string, cmd?: () => void) {
    const label = title || this.translate.instant("app.base.form.button.yes");
    this.commands.push({ order: 1, title: label, callback: cmd ?? this.nullCommand });
    return this;
  }

  /**
   * Adds an "No" option to this dialog.
   *
   * @param cmd An (optional) action / command to be executed when the user confirmed with "No"
   * @return The builder object
   */
  public withOptionNo(title?: string, cmd?: () => void) {
    const label = title || this.translate.instant("app.base.form.button.no");
    this.commands.push({ order: 0, title: label, callback: cmd ?? this.nullCommand });
    return this;
  }

  /**
   * Builds the configuration for the modal dialog. Should be the last call in a series of method invocations.
   *
   * @return The configuration instance
   */
  public build(): DialogConfig {
    return {
      data: {
        title: this.title,
        msg: this.message,
        actions: [...this.commands].sort((cmd1: OrderedDialogCommand, cmd2: OrderedDialogCommand) => cmd1.order - cmd2.order)
      },
      width: this.width || "90%",
      height: this.height || "auto",
      minWidth: this.minWidth,
      minHeight: this.minHeight
    };
  }

  public openOptionsDialog(dimensions?: OptionalDialogDimensions): MatDialogRef<OptionDialogComponent, OrderedDialogCommand> {
    const dialogSize: DialogDimensions = dimensions ?? { width: this.width, height: this.height };
    let config = this.commands.length === 0 ? this.withOptionYes().withOptionNo().build() : this.build();
    config = { ...config, ...dialogSize };

    return this.dialog.open(OptionDialogComponent, config);
  }

  private translateSanitized(msgKey: string, params?: Record<string, string>) {
    return params ? this.translate.instant(msgKey, this.sanitize(params)) : this.translate.instant(msgKey);
  }

  private sanitize(obj: Record<string, string>): Record<string, string> {
    if (obj) {
      return Object.entries(obj).reduce((tmp, [key, value]) => ({
        ...tmp, [key]: this.stripHtmlPipe.transform(value)
      }), {});
    }
    return obj;
  }

  private nullCommand = () => { };
}

@Injectable({
  providedIn: "root"
})
export class OptionDialogService {

  constructor(
    private translate: TranslateService,
    private matDialogService: MatDialog,
    private sanitizer: DomSanitizer
  ) { }

  public getConfigBuilder(): ConfigBuilder {
    return new ConfigBuilder(this.translate, this.matDialogService, new StripHtmlPipe(this.sanitizer));
  }
}
