import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Observable, of } from "rxjs";

import {
  AbstractSchadenmeldungFormularTargetDtoUnion,
  IdBezeichnungWithRefDto,
  SchadenGruppeDto
} from "connect-frontend-service";


@Component({
  selector: "lib-allgemeine-daten-step",
  templateUrl: "./allgemeine-daten-step.component.html",
  styleUrls: ["./allgemeine-daten-step.component.scss"]
})
export class AllgemeineDatenStepComponent {

  @Input() public formGroup: UntypedFormGroup;
  @Input() public schadenTyp: IdBezeichnungWithRefDto;
  @Input() public target: AbstractSchadenmeldungFormularTargetDtoUnion;
  @Input() public showButton$: Observable<boolean> = of(false);
  @Input() public schadenGruppen: SchadenGruppeDto[];

  constructor() { }


}
