//TODO: See Option interface
export interface DropdownOption {
    label: string;
    value: unknown;
    selected?: boolean;
    disabled?: boolean;
    active?: boolean;
    icon?: string;
}

export enum DropdownSortByOption {
    ACTIVE = "active",
    ASC = "asc",
    DESC = "desc"
}
