<div class="main-div">
  <ng-container *ngIf="validLink; then valid; else invalid">
  </ng-container>
  <ng-template #valid>
    <mat-card class="successCard" *ngIf="validLink">
      <mat-card-header>
        <mat-card-title>{{"success.headline" | translate}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <br />
        <p>{{ "success.schadennummer.teil" | translate }} <b>{{schadennummer}}</b></p>
        <p>
          {{ "success.schadenmeldung.download" | translate }}
        </p>
        <div *ngFor="let item of files">
          <button mat-button (click)="downloadPdfSchadenmeldung(item.id, item.token)" style="margin-top: 0.5em;">
            {{ item.fileName }}
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #invalid>
    <mat-card class="successCard" *ngIf="!validLink">
      <mat-card-header>
        <mat-card-title>{{"success.link.invalid" | translate}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        {{"success.link.content" | translate}}
        <div fxLayout="row" style="margin-top:14px;">
          <mat-icon>phone</mat-icon>
          <span class="telefon">05231 / 603-0</span>
        </div>

        <div fxLayout="row" style="margin-top:14px;">
          <mat-icon>mail</mat-icon>
          <span class="telefon">info&#64;ecclesia-gruppe.de</span>
        </div>

      </mat-card-content>
    </mat-card>
  </ng-template>
</div>