export const ALLGEMEINE_DATEN = "allgemeineDaten";
export const ALLGEMEINE_FRAGEN = "allgemeineFragen";

export const SCHADEN_TYP = "schadenTyp";
export const SCHADEN_DATUM = "schadenDatum";
/** The identifier of the server field config (containing all possible field values) */
export const SCHADEN_HOEHE = "schadenHoehe";
/** The identifier of the form control containing the destination of the report */
export const SCHADEN_TARGET = "target";

export const KUNDENNUMMER = "kundenNummer";
export const VERSICHERUNGS_NEHMER = "versicherungsNehmer";

export const BANKDATEN = "bankdaten";
export const IBAN = "iban";

export const BESCHREIBUNG = "beschreibung";
export const ANSPRECHPARTNER_DATEN = "ansprechpartnerDaten";
export const DATENSCHUTZSEITE = "datenschutzSeite";

export const SPEZIFISCHE_DATEN = "spezifischeDaten";
export const SCHADEN_ART_IDS = "schadenArtIds";
export const VERURSACHER_HAFTBAR = "verursacherHaftbar";
export const VERURSACHERID = "verursacherId";

export const CAPTCHA_SOLUTION_DATA = "captchaSolutionData";
export const CAPTCHAREQUESTID = "captchaRequestId";
export const PROPOSEDSOLUTION = "proposedSolution";
export const TENANT_FELDER = "tenantFields";
export const TOKEN = "token";
export const UNTERLAGEN = "unterlagen";
