import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatStepperModule } from "@angular/material/stepper";
import { TranslateModule } from "@ngx-translate/core";
import { StepperButtonsModule } from "connect-frontend-components/buttons";
import { DateiUploadModule } from "connect-frontend-components/datei-upload";
import {
  DatePickerFieldModule,
  KennzeichenInputModule,
  AddressInputModule,
  FmxDropdownModule,
  OptionsFilterModule,
  OptionTilesModule
} from "connect-frontend-components/inputs";
import { LoadingComponentModule } from "connect-frontend-components/loading";
import { EditorMatFormControlModule } from "connect-frontend-components/editor";
import { EditorModule } from "@tinymce/tinymce-angular";
import { FieldsModule } from "connect-frontend-components/fields";
import { EscapeHtmlModule } from "connect-frontend-components/text-utils";
import { IconsModule } from "connect-frontend-components/icons";
import { MatTooltipModule } from "@angular/material/tooltip";
import { OptionDialogModule } from "connect-frontend-components/option-dialog";
import { SchadenMeldungKachelModule } from "connect-frontend-components/inputs";
import { AllgemeineDatenStepComponent } from "./allgemeine-daten-step/allgemeine-daten-step.component";
import { AnsprechpartnerDatenStepComponent } from "./ansprechpartner-daten-step/ansprechpartner-daten-step.component";
import { BankverbindungStepComponent } from "./bankverbindung-step/bankverbindung-step.component";
import { BeschreibungStepComponent } from "./beschreibung-step/beschreibung-step.component";
import { DatenschutzStepComponent } from "./datenschutz-step/datenschutz-step.component";
import { InputComboboxSchadenmeldungComponent } from "./input-combobox/input-combobox-schadenmeldung.component";
import { InputTextFeldComponent } from "./input-text-feld/input-text-feld.component";
import { LoadingDialogComponent } from "./loading-dialog/loading-dialog.component";
import { SchadenMeldungComponent } from "./schaden-meldung.component";
import { SpezielleDatenStepComponent } from "./spezielle-daten-step/spezielle-daten-step.component";
import { VersicherungsnehmerStepComponent } from "./versicherungsnehmer-step/versicherungsnehmer-step.component";
import { TenantfelderDatenStepComponent } from "./tenantfelder-daten-step/tenantfelder-daten-step.component";
import { StepIndicatorComponent } from "./step-indicator/step-indicator.component";
import { UnterlagenStepComponent } from "./unterlagen-step/unterlagen-step.component";


@NgModule({
  declarations: [
    SchadenMeldungComponent,
    AllgemeineDatenStepComponent,
    AnsprechpartnerDatenStepComponent,
    BankverbindungStepComponent,
    BeschreibungStepComponent,
    UnterlagenStepComponent,
    DatenschutzStepComponent,
    SpezielleDatenStepComponent,
    VersicherungsnehmerStepComponent,
    InputComboboxSchadenmeldungComponent,
    InputTextFeldComponent,
    LoadingDialogComponent,
    TenantfelderDatenStepComponent,
    StepIndicatorComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    TranslateModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatIconModule,
    FlexLayoutModule,
    MatButtonModule,
    MatInputModule,
    StepperButtonsModule,
    MatCheckboxModule,
    MatSelectModule,
    DatePickerFieldModule,
    AddressInputModule,
    DateiUploadModule,
    KennzeichenInputModule,
    LoadingComponentModule,
    EditorMatFormControlModule,
    EditorModule,
    FieldsModule,
    OptionsFilterModule,
    FmxDropdownModule,
    EscapeHtmlModule,
    IconsModule,
    OptionTilesModule,
    MatTooltipModule,
    OptionDialogModule,
    SchadenMeldungKachelModule,
    FieldsModule
  ],
  exports: [
    SchadenMeldungComponent
  ]
})
export class SchadenMeldungModule { }
