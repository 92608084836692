import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { DynamicFormGroup } from "connect-frontend-components/fields";
import { AbstractSchadenMeldungFunction } from "../AbstractSchadenMeldungFunction";

@Component({
  selector: "lib-spezielle-daten-step",
  templateUrl: "./spezielle-daten-step.component.html",
  styleUrls: ["./spezielle-daten-step.component.scss"]
})
export class SpezielleDatenStepComponent extends AbstractSchadenMeldungFunction implements OnInit {

  @Input()
    spezielleDatenFormGroup: DynamicFormGroup;
  @Input()
    isArztHaftpflichtTyp: boolean;

  constructor(translate: TranslateService) {
    super(translate);
  }

  ngOnInit(): void {

  }

  getFormFieldErrorMessageComponent(key: string): string {
    // Unused
    return "";
  }

  get today() {
    return moment();
  }

}
