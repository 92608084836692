import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { TextParser, TextParserSettings } from "./text-parser";

/**
 * Displays a HTML content with some whitelisted tags.
 */
@Component({
  selector: "lib-safe-html",
  templateUrl: "./safe-html.component.html",
  styleUrls: ["./safe-html.component.scss"]
})
export class SafeHtmlComponent implements AfterViewInit, OnChanges {

  @ViewChild("htmlcontainer", { static: false }) htmlcontainer: ElementRef<HTMLElement>;

  @Input()
  public settings: TextParserSettings;

  @Input()
  public html: string;

  @Input()
  private keepEmptyLines? = true;

  // These elements are empty by design, and would otherwise not being rendered.
  private elementsWithoutClosingTag: string[] = [
    "area",
    "base", "br",
    "col", "command",
    "embed",
    "hr",
    "img", "input",
    "keygen",
    "link",
    "meta",
    "param",
    "source",
    "track",
    "wbr"
  ];

  constructor() { }

  ngAfterViewInit() {
    this.updateHtml();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.whiteListTags || changes.whiteListAttrs || changes.html) {
      this.updateHtml();
    }
  }

  private updateHtml() {
    if (this.htmlcontainer) {
      const textParser = new TextParser();
      textParser.settings = this.settings;
      textParser.elementShouldBeConverted = (element) => !this.isEmptyElement(element);
      textParser.parse(this.html || "", this.htmlcontainer.nativeElement);
    }
  }

  private isEmptyElement(el: Element) {
    return !this.keepEmptyLines
      && (el.innerHTML === "&nbsp;" || el.innerHTML === " " || el.innerHTML === "")
      && (!this.elementsWithoutClosingTag.includes(el.tagName.toLowerCase()));
  }
}
