import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import moment, { Duration } from "moment";
import { first, Observable, map, Subject, zip, of } from "rxjs";
import { UserNotification } from "./interfaces/user-notification";

const hasStatus = (error: unknown): error is { status: number } => typeof error === "object"
  && error !== null
  && "status" in error
  && typeof (error as { status: any }).status === "number";

type Headline = string | Observable<string>;
type Message = string | Observable<string>;

/** The interface used to clean up (remove a toast from the UI) */
export interface Dismissible {
  reference?: () => UserNotification;
  dismiss: () => void;
}

export type NotificationIcon = "default" | "error" | "done" | "spinner";

export interface NotificationConfig {
  headline: Headline;
  message: Message;
  icon: NotificationIcon;
  dur?: number | Duration;
  cssClass?: string;
}

/**
 * A flexible builder enabling clients to customize every aspect of the user notification system. It provides a
 * collection of utility methods that construct default configuration objects for the most common notification
 * cases (success, error, info).
 */
export class NotificationConfigBuilder {

  private readonly params: NotificationConfig = {
    headline: "",
    message: "",
    icon: "default",
    dur: null,
    cssClass: CustomSnackbarService.DEFAULT_CSS_CLASS
  };

  constructor(headline: Headline, message: Message) {
    this.params.headline = headline;
    this.params.message = message;
  }

  public forError(): NotificationConfigBuilder {
    this.params.icon = "error";
    this.params.cssClass = CustomSnackbarService.DEFAULT_ERROR_CSS_CLASS;
    return this;
  }

  public forSuccess(): NotificationConfigBuilder {
    this.params.icon = "done";
    return this;
  }

  public withDuration(dur: number | Duration): NotificationConfigBuilder {
    this.params.dur = dur;
    return this;
  }

  public withIcon(icon: NotificationIcon): NotificationConfigBuilder {
    this.params.icon = icon;
    return this;
  }

  public build(): NotificationConfig {
    return this.params;
  }
}

@Injectable({
  providedIn: "root"
})
export class CustomSnackbarService {

  public static readonly DEFAULT_DURATION: Duration = moment.duration(3000);
  public static readonly DEFAULT_INFO_DURATION: Duration = moment.duration(1500);
  public static readonly DEFAULT_CSS_CLASS: string = "default";
  public static readonly DEFAULT_ERROR_CSS_CLASS: string = "error";

  private readonly snackbarSubject: Subject<UserNotification> = new Subject();
  private readonly dismissSubject: Subject<number> = new Subject();
  private idCounter = 0;

  private _ref: any;

  constructor(
    private translate: TranslateService
  ) { }

  public displayGeneralError(error: unknown): Dismissible {
    // Typescript cannot narrow down the type.
    // https://github.com/microsoft/TypeScript/issues/25720 for typeof

    let message = this.translate.instant("error.status.unknown");
    if (hasStatus(error)) {
      switch (error.status) {
      case 400:
        message = this.translate.instant("error.status.400");
        break;
      case 401:
        message = this.translate.instant("error.status.403");
        break;
      case 403:
        message = this.translate.instant("error.status.403");
        break;
      case 404:
        message = this.translate.instant("error.status.404");
        break;
      case 500:
        message = this.translate.instant("error.status.500");
        break;
      case 503:
        message = this.translate.instant("error.status.503");
        break;
      default:
        message = this.translate.instant("error.status.default");
        break;
      }
    }
    if (typeof error === "object" && error) {
      (error as { __handled_magic: boolean }).__handled_magic = true;
    }
    return this.notify(this.translate.get("app.base.error.title.casual"), message, "error", null);
    /* tslint:enable:no-string-literal */
  }

  public get ref(): any {
    return this._ref;
  }

  public set ref(value: any) {
    this._ref = value;
  }

  public get messageSubmitter(): Observable<UserNotification> {
    return this.snackbarSubject.asObservable();
  }

  public get dismissSubmitter() {
    return this.dismissSubject.asObservable();
  }

  public notifyError(headline: Headline, message: Message): Dismissible {
    return this.notify(
      headline,
      message,
      "error",
      CustomSnackbarService.DEFAULT_DURATION,
      CustomSnackbarService.DEFAULT_ERROR_CSS_CLASS
    );
  }

  /**
   * Provide a visual feedback to the user using a popup window after successful event has occurred.
   *
   * @param headline The title of the window
   * @param message The content of the window
   * @return The object used to clean up the UI
   */
  public notifySuccess(headline: Headline, message: Message): Dismissible {
    return this.notify(
      headline,
      message,
      "done",
      CustomSnackbarService.DEFAULT_DURATION,
      CustomSnackbarService.DEFAULT_CSS_CLASS
    );
  }

  public notifyInfo(headline: Headline, message: Message): Dismissible {
    return this.notify(
      headline,
      message,
      "spinner",
      CustomSnackbarService.DEFAULT_INFO_DURATION,
      CustomSnackbarService.DEFAULT_CSS_CLASS
    );
  }

  /**
   * Provides a visual feedback to the user that started an operation which may be potentially long-lived.
   * <br/>Note that the pop-up window <b>does not</b> remain displayed for {@link DEFAULT_INFO_DURATION} amount of time
   * but is immediately removed from the UI.
   *
   * @param headline The title of the window
   * @param message The content of the window
   * @return The object used to release UI resources
   */
  public notifyProgress(headline: Headline, message: Message): Dismissible {
    return this.notify(
      headline,
      message,
      "spinner",
      null,
      CustomSnackbarService.DEFAULT_CSS_CLASS
    );
  }

  public notifyUsing(config: NotificationConfig): Dismissible {
    return this.notify(config.headline, config.message, config.icon, config.dur, config.cssClass);
  }

  /**
   * Provide a visual feedback to the user using a popup window after an event has occurred.
   *
   * @param headline The title of the window
   * @param message The content of the window
   * @param icon The icon
   * @param dur (Optional) The time to live before the window gets removed. This can be a number indicating the amount
   * of time in milliseconds (1s = 1000ms) or a moment object like moment(2, "seconds")
   * @param cssClass (Optional) The CSS-Class
   */
  public notify(
    headline: string | Observable<string>,
    message: string | Observable<string>,
    icon: string,
    dur?: number | Duration,
    cssClass?: string
  ): Dismissible {

    const messageAsObservable = typeof message !== "string"
      ? message
      : message
        ? this.translate.get(message)
        : of("");
    const headlineAsObservable = typeof headline !== "string"
      ? headline
      : headline
        ? this.translate.get(headline)
        : of("");

    let dismissed = false;
    let dismiss = () => {
      // Before observable completes, this will abort
      dismissed = true;
    };
    let uNot = () => null;

    zip(headlineAsObservable, messageAsObservable)
      .pipe(
        first(),
        map(([resolvedHeadline, resolvedMessage]): UserNotification => ({
          headline: resolvedHeadline,
          message: resolvedMessage,
          icon,
          dur: dur ? moment.isDuration(dur) ? dur : moment.duration(dur) : moment.duration(0),
          customclass: cssClass || "error",
          id: this.idCounter++
        }))
      ).subscribe((msg: UserNotification) => {
        if (!dismissed) {
          this.snackbarSubject.next(msg);
          dismiss = () => {
            // After observable completes, we need to notify dismiss subject so that message is removed form UI
            this.dismissSubject.next(msg.id);
          };
          uNot = (): UserNotification => msg;
        }
      });
    return {
      reference: () => uNot(),
      dismiss: () => dismiss() // < Need to proxy this so that update of the variable works
    };
  }
}
