<div fxLayout="column">
  <div class="formStepHeadline">
    <h4 class="fieldtitle">Wie können wir Sie erreichen?</h4>
    <mat-icon [matTooltip]="infoToolTip">info</mat-icon>
  </div>

  <div>
    <lib-address-input
      [borderAndTitle]="false"
      [requiredForFields]="[true, false, false, false, true, true]"
      [visible]="[true, false, false, false, true, true]"
      [formControl]="ansprechpartnerFormGroup.get('ansprechpartner')"
      fxFlex
    ></lib-address-input>
  </div>
</div>
