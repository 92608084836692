<div fxLayout="row" class="indicatorWrapper" id="step-indicator-container">
  <ng-container *ngFor="let step of steps">
    <div *ngIf="step.visible" class="stepIndicator" [ngClass]="{ checked: step.checked, active: step.active}" [attr.id]="'step-'+step.id">
      <div fxLayout="row" class="innerWrapper">
        <div class="circle">
          <div class="wrapper">
            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
        </div>
        <div class="label">{{ step.label }}</div>
      </div>
    </div>
  </ng-container>
</div>